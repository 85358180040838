<template>
  <main class=" ">
    <div class="mb-16 px-4 max-w-sm md:max-w-3xl lg:max-w-6xl mx-auto">
      <div class="flex justify-between items-center my-10">
        <h2
          class="uppercase text-lg md:text-2xl font-semibold text-gray-700 dark:text-gray-200"
        >
          contentsMain
        </h2>
      </div>

 
      <div
        class="bg-gray-50 dark:text-gray-400 dark:bg-gray-800 rounded-lg shadow-md relative mt-6"
      >
        <div v-if="showcontents" class="w-full whitespace-no-wrap">
          <div
            class="flex justify-between items-center pt-4 pb-2 text-xs font-semibold tracking-wide text-left text-gray-500 border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
          >
            <span class="w-1/2 md:w-1/4 px-4 py-3">Title</span>
            <span class="w-1/2 md:w-1/4 px-4 py-3">Iamge</span>
            <span class="w-1/2 md:w-1/4 px-4 py-3">Created At</span>
            <span class="w-1/2 md:w-1/4 px-4 py-3">Procedures</span>
          </div>
          <div class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
            <div
              :key="id"
              v-for="(content, id) in contents"
              class="flex justify-between items-center text-gray-700 dark:text-gray-400"
            >
              <span class="w-1/2 md:w-1/4 px-4 py-3 text-sm">
                <div>
                  <p class="font-semibold">{{ content.title }}</p>
                </div>
              </span>
              <span class="w-1/2 md:w-1/4 px-4 py-3">
                <div class="flex items-center text-sm">
                  <div
                    class="relative hidden w-10 h-10 ml-3 rounded-full md:block"
                  >
                    <img :src="content.contentImage" />
                    <div
                      class="absolute inset-0 rounded-full shadow-inner"
                      aria-hidden="true"
                    ></div>
                  </div>
                </div>
              </span>
              <span class="w-1/2 md:w-1/4 px-4 py-3 text-sm">
                {{ content.createdOn }}
              </span>
              <span class="w-1/2 md:w-1/4 px-4 py-3 text-sm">
                <div class="flex justify-between items-center">
                  <div class="">
                    <router-link
                      class=""
                      tag="a"
                      :to="{
                        name: 'contentsMain-edit',
                        params: { content: content.contetntMainId },
                      }"
                    >
                      <svg
                        class="w-6 h-6"
                        height="401pt"
                        viewBox="0 -1 401.52289 401"
                        width="401pt"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0"
                        />
                        <path
                          d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0"
                        />
                      </svg>
                    </router-link>
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div
            class="flex justify-between items-center px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
          >
            <el-pagination
              background
              :page-size="filter.pageSize"
              layout="prev, pager, next"
              :total="total"
              :current-page.sync="filter.pageNo"
              @current-change="PageChanged"
              class="pagination justify-content-center pagination-sm"
            >
            </el-pagination>
          </div>
        </div>

        <div
          v-else
          id="emptyState"
          class="text-gray_5 text-sm text-center h-96 flex items-center justify-center"
        >
          <div
            v-if="loading"
            id="testtest"
            class="h-64 flex justify-center items-center"
          >
            <svg
              class="w-10 h-w-10 rounded-full"
              viewBox="0 0 38 38"
              stroke="#fff"
            >
              <g fill="none">
                <g transform="translate(1 1)" stroke-width="2">
                  <circle
                    stroke="#E1E7EC"
                    stroke-opacity=".5"
                    cx="18"
                    cy="18"
                    r="18"
                  />
                  <path stroke="black" d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 18 18"
                      to="360 18 18"
                      dur="1s"
                      repeatCount="indefinite"
                    />
                  </path>
                </g>
              </g>
            </svg>
          </div>

          <div
            v-else
            class="h-64 flex justify-center items-center w-full bg-white dark:bg-gray-800"
          >
            There are no contents...
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  created() {},
  mounted() {
    this.getcontents();
  },
  data() {
    return {
      filter: {
        pageNo: 1,
        pageSize: 10,
        searchByName: "",
      },

      data: "",
      loading: false,
      showPagination: false,

      pagination: {},

      total: 0,

      displayClearButton: false,

      toWatchcontentsLength: "",
      showcontents: false,

      endDate: null,
      startDate: null,
      contents: [],
      title: null,

      makingSureDeleteIt: false,
      deleteStatus: false,
      prepareDeleteCategoryName: "",
      prepareDeleteCategoryId: "",
      prepareDeleteCategoryIndex: "",
    };
  },

  watch: {
    toWatchcontentsLength: function () {
      this.showcontents = false;
      this.loading = true;
      if (this.toWatchcontentsLength > 0) {
        setTimeout(() => {
          this.showcontents = true;
          this.loading = false;
        }, 1000);
      } else {
        setTimeout(() => {
          this.showcontents = false;
          this.loading = false;
        }, 1000);
      }
    },
  },

  methods: {
    PageChanged(pageNo) {
      this.filter.pageNo = pageNo;
      this.getcontents();
    },

    clearSearchInput() {
      this.filter.searchByName = "";
      this.getcontents();
    },

    getcontents() {
      this.loading = true;
      this.$http.contentsMainsService
        .GetContents(this.filter)
        .then((res) => {
          this.data = res.data;
          this.contents = res.data.result.contents;
          this.total = res.data.result.total;
          this.toWatchcontentsLength = res.data.result.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #171122;
  color: #fff;
}
</style>
